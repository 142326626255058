import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { SEO } from '@hallmark/web.page-components.seo';
import AddressConfirmation from '../../components/address-confirmation/address-confirmation';
import { AddressForm } from '../../components/address-form/address-form';
import { AddressFormFooterButtonsProps, GetAddressesProps } from '../../components/address-form/address-form-types';
import { BulkUploadValidationModal } from '../../components/bulk-upload-validation-modal/bulk-upload-validation-modal';
import { EmailAddressForm } from '../../components/email-address-form/email-address-form';
import { EnvelopePreview } from '../../components/envelope-preview/envelope-preview';
import { Layout } from '../../components/layout';
import { useGetAddresses } from '../../components/recipient-addresses-dialog/hooks/use-get-addresses';
import { AddressBookProvider } from '../../context/address-book-context/address-book-context';
import { useAnalyticsContext } from '../../context/analytics-context';
import { hideLoadingScreen, setIsToasterOpen, showLoadingScreen, useAppContext } from '../../context/app-context';
import { useCardContext } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import { CardType } from '../../global-types';
import { EnvelopeType } from '../../global-types/envelope';
import { useLineItemUUID } from '../../hooks';
import { useIsOneToMany } from '../../hooks/use-is-one-to-many';
import { config, getRegionalConfig } from '../../regional-config';
import { getAccountIdFromCookie, getIsDigitalGreeting, isLoggedIn, loginRedirect } from '../../utils';
import { useLoadExistingProject } from '../editor/hooks';
import { SubmittedAddresses } from './address-types';
import { defaultFormValues } from './address.data';
import styles from './address.module.scss';
import { AddressFooter } from './components/address-footer/address-footer';
import { useOneToManyNavigation } from './hooks/use-one-to-many-navigation';
import { useOneToOneNavigation } from './hooks/use-one-to-one-navigation';
import { useAddressBook } from './hooks/useAddressBook';
import { useAddressConfirmationDialog } from './hooks/useAddressConfirmationDialog';
import { useAddressEmailSubmit } from './hooks/useAddressEmailSubmit';
import { useAddressGoBack } from './hooks/useAddressGoBack';
import { useAddressSkipValidation } from './hooks/useAddressSkipValidation';
import { useAddressSubmit } from './hooks/useAddressSubmit';
import { useEnvelopeAddresses } from './hooks/useEnvelopeAddresses';
import { useLoadCardAfterLogin } from './hooks/useLoadCardAfterLogin';
import { useMigrateAndLoadProject } from './hooks/useMigrateAndLoadProject';
import { useSubmitAddresses } from './hooks/useSubmitAddresses';
import { populatePreviewImages } from './utils';
import { getIsAddressFormValid } from './utils/get-is-address-form-valid';

export const AddressView = (): ReactElement => {
  // State variables
  const [step, setStep] = useState(0);
  const [validAddresses, setValidAddresses] = useState(false);
  const [submittedAddresses, setSubmittedAddresses] = useState<SubmittedAddresses>({ recipient: null, sender: null });
  const [shouldSave, setShouldSave] = useState(false);

  const [preloadVerified, setPreloadVerified] = useState(false);
  const [triggerPageRefreshTracking, setTriggerPageRefreshTracking] = useState(true);
  const { appDispatch } = useAppContext();
  const { cardDispatch } = useCardContext();
  const {
    getAddresses,
    isOpen: isDialogOpen,
    setIsOpen: setIsDialogOpen,
    isLoading: isGetAddressesLoading,
    contacts,
  } = useGetAddresses();

  // Routing
  const navigate = useNavigate();
  const lastProjectLoaded = sessionStorage.getItem('lastProjectLoaded');
  const isDataLoaded = useRef<boolean>(false);
  const { search } = useLocation();

  // Contexts
  const {
    initializedDataState: { data: initializedData, lineItemUUID, isUK },
  } = useInitializationDataContext();
  const { appState } = useAppContext();
  const { previewImages, skipSpellcheckValidation } = appState;
  const isOneToMany = useIsOneToMany();
  const loadExistingProject = useLoadExistingProject();
  const { trackRefresh, trackEmailDeliveryRecipient, trackClickAddressRecipient } = useAnalyticsContext();

  // Translation
  const { t } = useTranslation();

  const isDigitalGreeting = getIsDigitalGreeting();
  // Get the addresses to fill based on country and envelope selection
  const addressTypesToFill = useEnvelopeAddresses();
  const addressType = addressTypesToFill[+step];
  const { isAddressRegulationToastEnabled } = config;
  const {
    addressForm: { shouldShowOneToManyEnvelopePreview },
  } = getRegionalConfig();
  // Change to true to see the bulk upload validation modal
  const showValidationBulkUploadModal = false;

  useEffect(() => {
    if (isAddressRegulationToastEnabled && !isDigitalGreeting) {
      setIsToasterOpen(appDispatch, {
        title: '',
        children: t('addressView.warningShippingMessage'),
        variant: ToastVariants.Warning,
      });
    }
  }, []);

  // Migrate and load the migrated project after the user logs in and store it in data-context
  useMigrateAndLoadProject();
  // After login personalizationData (used for preview) is empty
  // Populates cardState and personalizationData with the loaded project
  const { loadCardDataAfterLogin } = useLoadCardAfterLogin();
  // Preserve lineItemUUID on query string and data-context
  useLineItemUUID();
  // * Address Confirmation Dialog
  const {
    addAddressToValidate,
    closeAddressConfirmationDialog,
    addressesToValidate,
    openAddressConfirmationDialog,
    isConfirmationDialogOpen,
  } = useAddressConfirmationDialog();

  const { emailValues, handleEmailFormSubmit, isValidEmail, getValuesEmailForm, handleEmailSubmit } =
    useAddressEmailSubmit({ setShouldSave });

  const loadProject = async () => {
    showLoadingScreen(appDispatch, '');
    await loadExistingProject(lastProjectLoaded || '', isDataLoaded);
    hideLoadingScreen(appDispatch);
  };

  const { addressFormDefaultData, formValues, handleAddressSubmit } = useAddressSubmit({
    setSubmittedAddresses,
    step,
    setStep,
    setShouldSave,
  });

  const {
    handleSubmit: handleAddressFormSubmit,
    formState: { isValid: isAddressFormValid, isDirty: isAddressFormTouched },
    reset: resetFormAddresses,
    getValues,
    watch,
  } = formValues;

  // If card is a 1:many card and the envelope preview is enabled, show the envelope preview
  const shouldShowEnvelope =
    shouldShowOneToManyEnvelopePreview && isOneToMany && initializedData?.project_type_code === CardType.POD;

  const envelope = shouldShowEnvelope ? watch('envelope') : null;

  const shouldValidateForm = shouldShowEnvelope ? envelope !== 'blank' : true;

  const { onSkipValidation, skipAddressValidation } = useAddressSkipValidation({
    submittedAddresses,
    setSubmittedAddresses,
    setShouldSave,
    envelope,
  });

  const { goBack } = useAddressGoBack({ step, setStep, isDigitalGreeting, envelope });

  useEffect(() => {
    const defaultValues = { ...defaultFormValues, ...addressFormDefaultData };
    resetFormAddresses(defaultValues);
  }, [addressFormDefaultData]);

  const submitLabel = useMemo(() => {
    // Show preview step when on last addressing step, or when card is DG or One:Many, which have only one step.
    const shouldMoveToPreview = addressTypesToFill.length === step + 1 || isDigitalGreeting || isOneToMany;
    return shouldMoveToPreview ? t('addressForm.preview') : t('addressForm.continue');
  }, [step, addressTypesToFill, t]);

  // Address Book: Loads address-book if the user is logged in and returns a function to save a contact
  const { saveContact, isLoadingAddresses, getNextPage } = useAddressBook();

  // After both forms are submitted (or skipped validation in confirmation dialog if needed) and stored in the state variable submittedAddresses
  // we want to get that info and call the addresses API to store the submitted addresses in the project
  const { isAddressLoading, setIsAddressLoading } = useSubmitAddresses({
    shouldSave,
    submittedAddresses,
    setValidAddresses,
    saveContact,
    setShouldSave,
    addAddressToValidate,
    openAddressConfirmationDialog,
  });

  // Load local project data. Attempts to use data from initialize first
  // falls back to saved session data
  const getLocalProjectData = () => {
    if (initializedData !== null) {
      return {
        project_type_code: initializedData.project_type_code,
        account_id: initializedData.account_id,
        project_id: initializedData.project_id,
        lineItemUUID,
      };
    }

    return {
      project_type_code: sessionStorage.getItem('lastProjectTypeCode'),
      account_id: getAccountIdFromCookie(),
      project_id: lastProjectLoaded,
      lineItemUUID,
    };
  };

  useEffect(() => {
    const projectData = getLocalProjectData();
    if (
      projectData.project_type_code === CardType.DG &&
      !isLoggedIn() &&
      !process.env.REACT_APP_IS_ECARD_LOGIN_REDIRECT_DISABLED
    ) {
      // Redirect if we're editing a DG and the user is not logged in
      loginRedirect(projectData.project_id as string, projectData.account_id as string, projectData.lineItemUUID, isUK);
    } else {
      // Show address form if logged in already
      setPreloadVerified(true);
    }
    if (initializedData === null) {
      if (isLoggedIn()) {
        loadCardDataAfterLogin();
      } else {
        loadProject();
      }
    } else {
      // If initilization data exists, check if we have preview images stored
      // If we don't, user reloaded. Populate state with preview images in assets data
      if (previewImages.length === 0 && initializedData?.assets && initializedData.assets.length > 0) {
        populatePreviewImages(initializedData, appDispatch, cardDispatch);
      }
    }
    if (skipSpellcheckValidation) {
      setIsToasterOpen(appDispatch, {
        title: t('spellcheckWarningToast.title'),
        children: t('spellcheckWarningToast.description'),
        variant: ToastVariants.Warning,
      });
    }
  }, [initializedData]);

  // After all addresses were successfully sent to the BE, then we want to push the user to the preview view
  useEffect(() => {
    if (validAddresses) {
      // store envelope selection in the url
      const searchParams = new URLSearchParams(search);
      if (envelope) {
        searchParams.set('envelope', envelope);
      }
      navigate({ pathname: '/card/customization/preview', search: searchParams.toString() });
    }
  }, [validAddresses]);

  useEffect(() => {
    if (isDataLoaded.current && triggerPageRefreshTracking) {
      trackRefresh();
      setTriggerPageRefreshTracking(false);
      if (isDigitalGreeting) {
        trackEmailDeliveryRecipient();
      } else {
        trackClickAddressRecipient();
      }
    }
  }, [isDataLoaded.current]);

  // When dialog is closed, we want enable the preview button.
  useEffect(() => {
    if (!isConfirmationDialogOpen) {
      setIsAddressLoading(false);
    }
  }, [isConfirmationDialogOpen]);

  const onHandleGoBack = () => {
    const emailFormValues = getValuesEmailForm();
    const addressFormValues = getValues();
    goBack(addressFormValues, emailFormValues);
  };

  const isFormValid = getIsAddressFormValid({
    isDigitalGreeting,
    isValidEmail,
    isAddressFormValid,
    shouldValidateForm,
  });

  const handleSubmit = () => {
    // When card is DG or One:Many, which have only one step could fired the submit.
    if (isDigitalGreeting) {
      return handleEmailFormSubmit(handleEmailSubmit);
    }
    return shouldValidateForm ? handleAddressFormSubmit(handleAddressSubmit) : skipAddressValidation;
  };

  const handleOnEditAddress = (stepToEdit: number) => {
    setStep(stepToEdit);
    closeAddressConfirmationDialog();
  };

  const getAddressesProps: GetAddressesProps = {
    getAddresses,
    isOpen: isDialogOpen,
    setIsOpen: setIsDialogOpen,
    isLoading: isGetAddressesLoading,
    contacts,
  };

  const { oneToOneProps } = useOneToOneNavigation({
    submitLabel,
    isFormValid,
    isAddressLoading,
    isAddressFormValid,
    isTouched: isAddressFormTouched,
    handlePrimaryActionButton: handleSubmit(),
    handleSecondaryActionButton: onHandleGoBack,
  });

  const { oneToManyProps } = useOneToManyNavigation({
    isFormValid,
    isDialogOpen,
    isAddressLoading,
    isAddressFormValid,
    isGetAddressesLoading,
    handlePrimaryActionButton: handleSubmit(),
    handleSecondaryActionButton: onHandleGoBack,
    addressFormValues: formValues,
    getAddresses,
  });

  const footerButtonsProps: AddressFormFooterButtonsProps = {
    oneToManyProps,
    oneToOneProps,
  };

  return (
    <AddressBookProvider>
      {showValidationBulkUploadModal && (
        <BulkUploadValidationModal
          addressCounter={['test', 'test2', 'test3']}
          currentAddressIndex={2}
          errorCounter={['error1', 'error2']}
        />
      )}
      <Layout hideToolbar toastId="toast">
        <SEO title={`${t('addressView.title')}`} description={`${t('addressView.seo')}`} />
        <div className={styles.address} data-testid="address-view">
          <div className={classNames(styles.content)}>
            {shouldShowEnvelope && <EnvelopePreview selectedEnvelope={envelope as EnvelopeType} />}
            {isDigitalGreeting ? (
              <EmailAddressForm formHandlers={emailValues} />
            ) : (
              preloadVerified && (
                <AddressForm
                  shouldRenderEnvelope={shouldShowEnvelope}
                  addressType={addressType}
                  formHandlers={formValues}
                  isLoadingAddresses={isLoadingAddresses}
                  getNextPage={getNextPage}
                  onSkipValidation={onSkipValidation}
                  setSubmittedAddresses={setSubmittedAddresses}
                  setShouldSave={setShouldSave}
                  getAddressesProps={getAddressesProps}
                  footerButtonsProps={footerButtonsProps}
                />
              )
            )}
          </div>
          <AddressFooter
            isValid={isFormValid}
            isAddressLoading={isAddressLoading}
            goBack={onHandleGoBack}
            submitLabel={submitLabel}
            handleSubmit={handleSubmit()}
          />
        </div>
        {isConfirmationDialogOpen && (
          <AddressConfirmation
            isOpen={isConfirmationDialogOpen}
            close={closeAddressConfirmationDialog}
            addressesToValidate={addressesToValidate}
            onSkipValidation={onSkipValidation}
            onEditAddress={handleOnEditAddress}
            showStepper={true}
            isAddressFromAddressBook={false}
            submitEditedAddress={null}
          />
        )}
      </Layout>
    </AddressBookProvider>
  );
};
