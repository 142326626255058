import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Dialog, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { DialogBrandColors } from '@hallmark/web.core.feedback.dialog/dist/dialog-types';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { getCurrentRegion } from '../../regional-config/get-regional-config';
import contactStyles from '../contact-card/contact-card.module.scss';
import { Contact as ContactCard } from '../contact/contact';
import { DetailRecipients } from './fragments/detail-recipients';
import { OrderQuantity } from './fragments/order-quantity';
import { useOrderQuantitySummary } from './hooks/use-order-quantity-summary';
import { RecipientAddressesProps, RecipientAddressesVariant } from './recipient-addresses-dialog-types';
import styles from './recipient-addresses-dialog.module.scss';

export const RecipientAddressesDialog = ({
  contacts,
  isOpen,
  setIsOpen,
  variant,
  onActionButtonClick,
  onSecondaryActionButtonClick,
}: RecipientAddressesProps) => {
  const { t } = useTranslation();
  const region = useMemo(() => getCurrentRegion(), []);
  const printedEnvelopes = contacts?.length ?? 0;
  const { quantity, blankEnvelopes } = useOrderQuantitySummary({ printedEnvelopes });

  const onEditContact = () => {
    // TODO: implement this
  };

  const onDeleteContact = () => {
    // TODO: implement this
  };

  return isOpen && contacts !== null ? (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      accentIcon={IconNames.AddressBookBold}
      accentIconColor={DialogBrandColors.White}
      title={String(t('recipientAddresses.title'))}
      type={DialogContentType.FeatureModal}
      footerType={DialogFooterType.WithShadow}
      locale={region}
      addDialogWrapperClass={styles.recipientAddressesWrapper}
      secondaryActionButton={
        <Button testId="secondary-action-button" click={onSecondaryActionButtonClick} mode={ButtonModes.Secondary}>
          {variant === RecipientAddressesVariant.Address
            ? t('recipientAddresses.address.secondaryActionButton')
            : t('recipientAddresses.preview.secondaryActionButton')}
        </Button>
      }
      actionButton={
        <Button testId="action-button" click={onActionButtonClick} mode={ButtonModes.Primary}>
          {variant === RecipientAddressesVariant.Address
            ? t('recipientAddresses.address.actionButton')
            : t('recipientAddresses.preview.actionButton')}
        </Button>
      }
    >
      <div className={styles.recipientAddresses}>
        <OrderQuantity quantity={quantity} />
        <DetailRecipients
          shouldEdit
          iconName={IconNames.Mail}
          description={t('recipientAddresses.detailRecipients.blankEnvelopes')}
          amount={blankEnvelopes}
        />
        <DetailRecipients
          iconName={IconNames.AddressBookBold}
          description={t('recipientAddresses.detailRecipients.printedEnvelopes')}
          amount={printedEnvelopes}
        />
        {contacts && contacts.length > 0 ? (
          contacts.map((contact, index) => (
            <div className={contactStyles['contact-card'] + ' ' + styles.contact} key={index}>
              <ContactCard contact={contact} onDelete={onDeleteContact} onEdit={onEditContact} />
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </Dialog>
  ) : (
    <></>
  );
};
