import type { AddressForm } from '../../../global-types/addresses';
import { saveAddresses, saveAddress, saveQuickAddress } from '../../../services/address';

export const handleAddressSubmitOneToMany = async (project_id: string, payload: AddressForm | AddressForm[]) => {
  if (Array.isArray(payload)) {
    const formattedAddresses = payload.map((data) => {
      const address = formatAddress(data);

      return {
        address,
        is_verified: data.skip_usps_validation,
      };
    });

    return saveAddresses(project_id, { addresses: formattedAddresses, skip_ebdw_validation: true });
  }

  if (payload.isQuickAddress) {
    await saveQuickAddress({
      ...formatAddress(payload),
      skip_address_verification: true,
      contact_type_code: 'A',
    });
  }

  return saveAddress(project_id, {
    ...formatAddress(payload),
    skip_usps_validation: payload.skip_usps_validation,
    address_type_code: 'R',
  });
};

function formatAddress(data: AddressForm) {
  return {
    first_name: data.first_name,
    last_name: data.last_name,
    company_name: data.company_name,
    address_line_1: data.address_line_1,
    address_line_2: data.address_line_2,
    city: data.city,
    state_code: data.state_code,
    zip: data.zip,
    country_code: data.country_code,
  };
}
