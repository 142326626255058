import { Contact } from '../../components/address-book';
import { AddressResponseData } from '../../global-types';

export const mapAddressesToContacts = ({
  addresses,
  accountId,
}: {
  addresses: AddressResponseData[] | null;
  accountId: string | undefined;
}): Contact[] => {
  if (!addresses || addresses.length === 0) return [];

  const contacts: Contact[] = [];
  addresses.forEach((address: AddressResponseData) => {
    if (address.address_type_code === 'R') {
      contacts.push({
        contact_id: address.address_id,
        account_id: accountId || '',
        first_name: address.first_name,
        last_name: address.last_name,
        company_name: address.company_name,
        address_line_1: address.address_line_1,
        address_line_2: address.address_line_2,
        city: address.city,
        state_code: address.state_code,
        zip: address.zip,
        country_code: address.country_code,
        contact_type_code: address.address_type_code,
        created_at: address.created_at,
        last_updated_at: address.last_updated_at,
        is_verified: address.is_verified,
      });
    }
  });

  return contacts;
};
