import { Contact } from '../address-book';

// export type RecipientAddressesVariant = 'address' | 'preview';

export enum RecipientAddressesVariant {
  Address = 'address',
  Preview = 'preview',
}

export type RecipientAddressesProps = {
  contacts: Contact[] | null;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  variant: RecipientAddressesVariant;
  // Functions to handle the actions buttons
  onActionButtonClick: () => void;
  onSecondaryActionButtonClick: () => void;
};
