import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Button, ButtonModes, ButtonTypes } from '@hallmark/web.core.buttons.button';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { TextField } from '@hallmark/web.core.forms.text-field';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAnalyticsContext } from '../../context/analytics-context';
import { setIsToasterOpen, setProductQuantity, useAppContext } from '../../context/app-context';
import { useInitializationDataContext } from '../../context/data-context';
import { useQueryParams } from '../../hooks';
import useAddressSelection from '../../hooks/use-address-selection';
import { config } from '../../regional-config';
import { updateProductQuantity } from '../../services/customization';
import { SummaryDrawer } from '../card-controls/summary-drawer';
import styles from './quantity.module.scss';

export const Quantity = () => {
  const [isProjectSummaryOpen, setIsProjectSummaryOpen] = useState(false);
  const { t } = useTranslation();
  const { trackChangeQuantityProjectSummary, trackViewProjectSummary } = useAnalyticsContext();
  const queryParams = useQueryParams();
  const { pathname } = useLocation();
  const [isSavingQuantity, setIsSavingQuantity] = useState(false);
  const navigate = useNavigate();
  const { appState, appDispatch } = useAppContext();
  const { productQuantity } = appState;
  const hasQuantityUpdatedAfterPageLoad = useRef(false);
  const isQuantityLinkEnabled = config?.header?.quantityLink;
  const isQuantityInputEnabled = config?.header?.quantityInput;
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const projectId = initializedData?.project_id;
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      quantity: productQuantity,
    },
    mode: 'onChange',
    delayError: 250,
  });

  const isValidQuantity = (quantity) => Number(quantity) > 0;
  const onCloseSummary = () => {
    setIsProjectSummaryOpen(false);
  };
  const updateQuantity = useCallback(
    async (qty: string) => {
      setIsSavingQuantity(true);
      const quantityBeforeUpdate = queryParams.get('qty');
      try {
        setProductQuantity(appDispatch, qty);
        queryParams.set('qty', qty);
        navigate({ pathname, search: queryParams.toString() }, { replace: true });
        await updateProductQuantity(qty, projectId);
      } catch (e) {
        setProductQuantity(appDispatch, quantityBeforeUpdate || '1');
        queryParams.set('qty', quantityBeforeUpdate || '1');
        navigate({ pathname, search: queryParams.toString() }, { replace: true });
        setValue('quantity', quantityBeforeUpdate || '1');
        setIsToasterOpen(appDispatch, {
          title: t('summaryDrawer.quantityChangeErrorTitle'),
          children: t('summaryDrawer.quantityChangeError'),
          variant: ToastVariants.Error,
        });
      }
      setIsSavingQuantity(false);
    },
    [queryParams, history],
  );

  // Setting max quantity to the address selection context
  const { handleSetMaxCount } = useAddressSelection();

  const debouncedUpdate = useDebouncedCallback((value) => {
    updateQuantity(value);
  }, 1000);

  useEffect(() => {
    handleSetMaxCount(Number(appState.productQuantity));
  }, [appState.productQuantity]);

  useEffect(() => {
    // start listening for changes in product quantity
    // after we've loaded the page and confirmed
    // the state equals the query params
    if (productQuantity === queryParams.get('qty') && hasQuantityUpdatedAfterPageLoad.current) {
      trackChangeQuantityProjectSummary();
    } else if (productQuantity === queryParams.get('qty')) {
      hasQuantityUpdatedAfterPageLoad.current = true;
    }
  }, [productQuantity]);

  useEffect(() => {
    setProductQuantity(appDispatch, queryParams.get('qty') ?? '1');
  }, []);

  useEffect(() => {
    if (isProjectSummaryOpen) {
      trackViewProjectSummary();
    }
  }, [isProjectSummaryOpen]);

  return (
    <>
      {isQuantityLinkEnabled && (
        <>
          <Button
            testId="quantity-link"
            click={() => setIsProjectSummaryOpen(!isProjectSummaryOpen)}
            type={ButtonTypes.Button}
            mode={ButtonModes.TextLink}
            addClass={styles['quantity-link']}
            endIcon={{ name: isProjectSummaryOpen ? IconNames.ArrowsCaretUpBold : IconNames.ArrowsCaretDownBold }}
          >
            {t('header.quantity', { productQuantity })}
          </Button>
          {isProjectSummaryOpen && (
            <SummaryDrawer
              isOpen={isProjectSummaryOpen}
              onClose={onCloseSummary}
              quantity={productQuantity}
              onUpdateQuantity={updateQuantity}
              isSavingQuantity={isSavingQuantity}
              register={register}
            />
          )}
        </>
      )}
      {isQuantityInputEnabled && (
        <TextField
          addClass={styles['quantity-input']}
          label="Quantity"
          domId="quantity"
          testId="quantity-input"
          isError={Boolean(errors.quantity)}
          helperText={errors.quantity ? errors.quantity.message : ''}
          type="number"
          onChange={(event) => isValidQuantity(event.target.value) && debouncedUpdate(event.target.value)}
          onKeyDown={(event) => ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault()}
          register={register('quantity', {
            required: { value: true, message: t('header.quantityRequiredError') },
            valueAsNumber: true,
          })}
        />
      )}
    </>
  );
};
