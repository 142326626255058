import { Address, AddressTypes, BaseAddress } from '../../global-types/addresses';

type MappedAddresses<T extends BaseAddress> = {
  [key in AddressTypes.RECIPIENT | AddressTypes.SENDER]?: T;
};

/**
 * Takes an array of addresses and maps it into an object with a recipient and sender keys. Checks whether the address_type_code and based on that value,
 * will determine it is a recipient or a sender address
 * @param addresses Array of addresses to be mapped into the returning object
 * @returns Object containing recipient and/or sender addresses
 */
export const mapAddressesByType = <T extends BaseAddress = Address>(addresses: T[]): MappedAddresses<T> => {
  const mappedAddresses: MappedAddresses<T> = {};
  addresses.forEach((address) => {
    if (address.address_type_code === 'S') {
      mappedAddresses.sender = address;
    } else {
      mappedAddresses.recipient = address;
    }
  });
  return mappedAddresses;
};
