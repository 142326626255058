import { useCallback, useState } from 'react';
import { useInitializationDataContext } from '../../../context/data-context/data-context';
import { useSystemErrorHandling } from '../../../hooks/useSystemErrorHandling';
import { getAddresses as fetchAddresses } from '../../../services/address';
import { mapAddressesToContacts } from '../../../utils/address/map-addresses-to-contacts';
import { Contact } from '../../address-book/address-book-types';

export const useGetAddresses = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [contacts, setContacts] = useState<Contact[] | null>(null);

  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { onSystemError } = useSystemErrorHandling();

  const projectId = initializedData?.project_id;
  const accountId = initializedData?.account_id;

  const getAddresses: () => void = useCallback(() => {
    if (projectId) {
      setIsLoading(true);
      fetchAddresses(projectId)
        .then((response) => {
          if (response.data) {
            setContacts(mapAddressesToContacts({ addresses: response.data, accountId }));
            setIsOpen(true);
            setIsLoading(false);
          } else {
            setContacts([]);
            setIsOpen(true);
          }
        })
        .catch((error) => {
          onSystemError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [projectId]);
  return { getAddresses, isOpen, setIsOpen, contacts, isLoading };
};
