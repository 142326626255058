import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getThemeZipLength, validStringRule, validZipCodeRule } from '../components/address-form/address-utils';
import { FormFields, FormFieldSelectedList } from '../components/form-fields-generator/form-fields-generator-types';
import styles from '../components/form-fields-generator/form-fields-generator.module.scss';
import { getSelectedFormFields } from '../components/form-fields-generator/utils/get-selected-form-fields';
import { useInitializationDataContext } from '../context/data-context';
import { AddressForm, PrefixForm } from '../global-types/addresses';
import { config } from '../regional-config';
import { getRequired } from '../utils';
import { handleZipChange } from '../utils/address/handle-zip-change';
import { useUsStateOptions } from './use-us-state-options';

/**
 * Custom hook to generate form fields based on provided parameters.
 * @param formHandlers The form handlers object from react-hook-form.
 * @param formPrefix The prefix for the form fields' DOM IDs.
 * @param selectedFormFieldsNames The list of selected form field names.
 *     The structure of this parameter is an array containing objects with the following structure:
 *     {
 *         typeOfField: string, // The type of the form field, such as 'textfield', 'dropdown', or 'collapse'.
 *         name: string, // The unique identifier or name of the form field.
 *         nestedFields?: { typeOfField: string, name: string }[] // An array of nested fields, applicable only when typeOfField is 'collapse'.
 *     }
 * @returns The list of selected form fields with their configurations.
 */
export const useFormFieldGenerator = (
  formHandlers: UseFormReturn<AddressForm>,
  formPrefix: string,
  selectedFormFieldsNames: FormFieldSelectedList[],
) => {
  const { t } = useTranslation();
  const shortFieldError = (min: number, max: number) => `${t('addressUtil.shortFieldError', { min, max })}`;
  const {
    initializedDataState: { isUS, isUK },
  } = useInitializationDataContext();
  const { usStateOptions } = useUsStateOptions();

  const {
    formState: { errors },
    trigger,
    setValue,
  } = formHandlers;

  const formFields: FormFields[] = [
    {
      typeOfField: 'textfield',
      name: 'first_name',
      label: `${t('addressForm.firstName')}`,
      registerOptions: {
        required: getRequired(PrefixForm.ADDRESS_FORM, 'firstName'),
        validate: validStringRule(t('addressUtil.invalidCharacterError')),
        maxLength: { value: 40, message: shortFieldError(2, 40) },
        minLength: { value: 2, message: shortFieldError(2, 40) },
      },
      domId: `${formPrefix}-form-first-name`,
      isError: Boolean(errors.first_name),
      helperText: errors?.first_name ? errors?.first_name?.message : '',
      addClass: styles['form-field'],
      testId: 'address_first_name',
      required: true,
      dropdownOptions: [],
    },
    {
      typeOfField: 'textfield',
      name: 'last_name',
      label: `${t('addressForm.lastName')}`,
      registerOptions: {
        required: getRequired(PrefixForm.ADDRESS_FORM, 'lastName'),
        validate: validStringRule(t('addressUtil.invalidCharacterError')),
        maxLength: { value: 40, message: shortFieldError(2, 40) },
        minLength: { value: 2, message: shortFieldError(2, 40) },
      },
      domId: `${formPrefix}-form-last-name`,
      isError: Boolean(errors.last_name),
      helperText: errors.last_name ? errors.last_name.message : '',
      addClass: styles['form-field'],
      testId: 'address_last_name',
      required: true,
      dropdownOptions: [],
    },
    {
      typeOfField: 'textfield',
      name: 'address_line_1',
      label: `${t('addressForm.addressLine1')}`,
      registerOptions: {
        required: getRequired(PrefixForm.ADDRESS_FORM, 'addressLine1'),
        maxLength: { value: 55, message: shortFieldError(1, 55) },
        minLength: { value: 1, message: shortFieldError(1, 55) },
      },
      domId: `${formPrefix}-form-address-line-1`,
      isError: Boolean(errors.address_line_1),
      helperText: errors.address_line_1 ? errors.address_line_1.message : '',
      addClass: styles['form-field'],
      testId: 'address_line_1',
      required: true,
      dropdownOptions: [],
    },
    {
      typeOfField: 'textfield',
      name: 'address_line_2',
      label: `${t('addressForm.addressLine2')}`,
      registerOptions: {
        maxLength: { value: 55, message: shortFieldError(1, 55) },
        minLength: { value: 1, message: shortFieldError(1, 55) },
      },
      domId: `${formPrefix}-form-address-line-2`,
      isError: Boolean(errors.address_line_2),
      helperText: errors.address_line_2 ? errors.address_line_2.message : '',
      addClass: styles['form-field'],
      testId: 'address_line_2',
      required: false,
      dropdownOptions: [],
    },
    {
      typeOfField: 'textfield',
      name: 'company_name',
      label: `${t('addressForm.companyName')}`,
      registerOptions: {
        maxLength: { value: 50, message: shortFieldError(0, 50) },
        minLength: { value: 0, message: shortFieldError(0, 50) },
      },
      domId: `${formPrefix}-form-company-name`,
      isError: Boolean(errors.company_name),
      helperText: errors.company_name ? errors.company_name.message : '',
      addClass: styles['form-field'],
      testId: 'company_name',
      required: false,
      dropdownOptions: [],
    },
    {
      typeOfField: 'textfield',
      name: 'city',
      label: `${t('addressForm.city')}`,
      registerOptions: {
        required: `${t('addressForm.isRequired', { requirement: 'City' })}`,
        validate: validStringRule(t('addressUtil.invalidCharacterError')),
        maxLength: { value: 26, message: shortFieldError(2, 26) },
        minLength: { value: 2, message: shortFieldError(2, 26) },
      },
      domId: `${formPrefix}-form-city`,
      isError: Boolean(errors.city),
      helperText: errors.city ? errors.city.message : '',
      addClass: styles['form-field'],
      testId: 'address_city',
      required: true,
      dropdownOptions: [],
    },
    {
      typeOfField: 'dropdown',
      name: 'state_code',
      label: t('addressForm.state'),
      registerOptions: {
        required: `${t('addressForm.isRequired', { requirement: 'State' })}`,
        maxLength: { value: 2, message: t('addressUtil.validStateCodeError') },
        minLength: { value: 2, message: t('addressUtil.validStateCodeError') },
      },
      domId: `${formPrefix}-form-state_code`,
      isError: Boolean(errors.state_code),
      helperText: errors.state_code ? errors.state_code.message : '',
      addClass: styles['form-field'],
      testId: 'address_state_code',
      required: true,
      showDropdown: isUS && usStateOptions.length > 0,
      dropdownOptions: [{ value: '', label: '' }, ...usStateOptions],
    },
    {
      typeOfField: 'textfield',
      name: 'zip',
      label: `${t('addressForm.zip')}`,
      registerOptions: {
        required: `${t('addressForm.isRequired', { requirement: isUK ? 'postcode' : 'ZIP code' })}`,
        validate: validZipCodeRule(t('addressUtil.validZipCodeError')),
        maxLength: { value: getThemeZipLength().max, message: t('addressUtil.validZipCodeError') },
        minLength: { value: getThemeZipLength().min, message: t('addressUtil.validZipCodeError') },
      },
      domId: `${formPrefix}-form-zip`,
      isError: Boolean(errors.zip),
      helperText: errors.zip ? errors.zip.message : '',
      addClass: styles['form-field'],
      testId: 'address_zip',
      required: true,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        handleZipChange(config?.addressForm?.handleZipChange, trigger, e.target.value, setValue),
      dropdownOptions: [],
    },
    {
      typeOfField: 'checkbox',
      name: 'isQuickAddress',
      label: `${t('addressForm.saveQuickAddress')}`,
      required: false,
      domId: `${formPrefix}-form-isQuickAddress`,
      addClass: styles['checkbox'],
      testId: 'address_isQuickAddress',
      dropdownOptions: [],
      isError: false,
      registerOptions: {},
      showCheckbox: isUS,
    },
  ];

  // selectedFormFieldsList is an array of FormFields that is passed as a parameter to the FormFieldGenerator component
  const selectedFormFieldsList = getSelectedFormFields(selectedFormFieldsNames, formFields);

  return selectedFormFieldsList;
};
