import { CustomerAuthResponseData, HallmarkMembershipStatus } from '../../global-types';
import { capitalizeFirstLetter } from '../utility/capitalize-first-letter';

export const parseMembershipData = (membershipData: CustomerAuthResponseData): HallmarkMembershipStatus => {
  if (!membershipData || !membershipData.c_CRMemberDetails) {
    // eslint-disable-next-line no-console
    console.warn('Could not get membership data');
    return {
      memberType: '',
      rewardsMembershipLevel: '',
      rewardId: '',
      plusMembership: '',
      customerId: '',
    };
  }

  const { c_CRMemberDetails, c_subscriptionHMPlusCode, c_subscriptionHMPlusCardNumber, customer_id } = membershipData;
  // c_CRMemberDetails is returned from the API as a JSON string so we need to parse it
  const crMemberDetails = JSON.parse(c_CRMemberDetails);

  const getMemberType = (isCRSetupComplete) => {
    let memberType = '';
    // Potential values for  values for "c_subscriptionHMPlusCode": Never, Trial, Monthly, Annual, Expired
    // See https://hallmark.atlassian.net/wiki/spaces/NMA/pages/4206755883/Enterprise+Subscriptions
    const isHMPlus = c_subscriptionHMPlusCode !== 'Never' && c_subscriptionHMPlusCode !== 'Expired';
    if (isCRSetupComplete && isHMPlus) {
      memberType = 'CR-HP';
    } else if (isCRSetupComplete) {
      memberType = 'CR';
    } else if (isHMPlus) {
      memberType = 'HP';
    }

    if (!c_subscriptionHMPlusCode) {
      memberType = 'CR-HP-Inactive';
    }

    return memberType;
  };

  const getPlusMembership = (c_subscriptionHMPlusCode) => {
    if (c_subscriptionHMPlusCode === 'H+ Monthly') {
      return 'Monthly';
    }
    if (!c_subscriptionHMPlusCode) {
      return 'Inactive';
    }
    return capitalizeFirstLetter(c_subscriptionHMPlusCode);
  };

  return {
    memberType: getMemberType(crMemberDetails.IsCRSetupComplete),
    rewardsMembershipLevel: crMemberDetails.Tier,
    rewardId: crMemberDetails.CRNumber || c_subscriptionHMPlusCardNumber,
    plusMembership: getPlusMembership(c_subscriptionHMPlusCode),
    customerId: customer_id,
  };
};
